
  import { mapState, mapActions } from 'vuex'

  export default {
    props: {
      option: {
        type: Object,
        default: () => {}
      },
      requiredOnly: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        supportsAlternateQuantity: false,
        alternateQuantityMultiplier: 1,
      }
    },
    computed: {
      ...mapState({
        choices: state => state.product.choices,
        product: state => state.product?.data
      }),
      mobile() {
        return this.$mq == 'sm' || this.$mq == 'md'
      },
      ingredientMods() {
        let foundSubGroup = false
        let ingredientMods = [{ options: [] }, { options: [] }]
        this.product.mods.forEach(mod => {
          mod.options.forEach(option => {
            if (option.id === this.option.id) {
              option.mods.forEach(subMod => {
                if (!foundSubGroup && ['Whole', 'Left', 'Right'].includes(subMod.name)) {
                  if (!this.requiredOnly || (this.requiredOnly && subMod.selects.mandatory)) {
                    foundSubGroup = true
                    ingredientMods = subMod.options[0].mods
                    if (subMod.meta && subMod.meta['supports-alternate-quantity'] === 'true') {
                      this.supportsAlternateQuantity = true
                    }
                    if (subMod.meta && subMod.meta['alternate-quantity-multiplier']) {
                      this.alternateQuantityMultiplier = Number(subMod.meta['alternate-quantity-multiplier'])
                    }
                  }
                }
              })
            }
          })
        })
        return ingredientMods
      },
      popular() {
        let ingredients = []
        this.ingredientMods[0].options.forEach(option => {
          if (option.name !== 'Additional Ingredients') {
            ingredients.push(option)
          }
        })
        return ingredients
      },
      proteins() {
        let ingredients = []
        this.ingredientMods[0].options.forEach(option => {
          if (option.name == 'Additional Ingredients') {
            option.mods.forEach(mod => {
              if (mod.name == 'Proteins') {
                mod.options.forEach(subOption => {
                  ingredients.push(subOption)
                })
              }
            })
          }
        })
        return ingredients
      },
      veggies() {
        let ingredients = []
        this.ingredientMods[0].options.forEach(option => {
          if (option.name == 'Additional Ingredients') {
            option.mods.forEach(mod => {
              if (mod.name == 'Add Veggies') {
                mod.options.forEach(subOption => {
                  ingredients.push(subOption)
                })
              }
            })
          }
        })
        return ingredients
      },
      cheeses() {
        let ingredients = []
        this.ingredientMods[0].options.forEach(option => {
          if (option.name == 'Additional Ingredients') {
            option.mods.forEach(mod => {
              if (mod.name == 'Add Cheeses') {
                mod.options.forEach(subOption => {
                  ingredients.push(subOption)
                })
              }
            })
          }
        })
        return ingredients
      },
      extras() {
        let ingredients = []
        if (
          this.ingredientMods &&
          Array.isArray(this.ingredientMods) &&
          this.ingredientMods.length > 0 &&
          this.ingredientMods[1]
        ) {
          this.ingredientMods[1].options.forEach(option => {
            ingredients.push(option)
          })
        }
        return ingredients
      },
      left() {
        return this.mobile ? 'L' : 'Left'
      },
      right() {
        return this.mobile ? 'R' : 'Right'
      },
      // Get a list of each of the modifiers for sides
      leftList() {
        let left = { options:[] }
        for (const mod of this.product.mods) {
          for (const opt of mod.options) {
            if (opt.id === this.option.id) {
              for (const subMod of opt.mods) {
                if (subMod.name == 'Left') {
                  left = subMod
                  break
                }
              }
            }
          }
        }
        return left
      },
      wholeList() {
        let whole = { options:[] }
        for (const mod of this.product.mods) {
          for (const opt of mod.options) {
            if (opt.id === this.option.id) {
              for (const subMod of opt.mods) {
                if (subMod.name == 'Whole') {
                  whole = subMod
                  break
                }
              }
            }
          }
        }
        return whole
      },
      rightList() {
        let right = { options:[] }
        for (const mod of this.product.mods) {
          for (const opt of mod.options) {
            if (opt.id === this.option.id) {
              for (const subMod of opt.mods) {
                if (subMod.name == 'Right') {
                  right = subMod
                  break
                }
              }
            }
          }
        }
        return right
      },
    },
    methods: {
      ...mapActions({
        selectMod: 'product/selectMod',
        removeMod: 'product/removeMod',
      }),
      showImages(ingredientList) {
        if (ingredientList && ingredientList.length > 0) {
          return !!ingredientList.find(ingredient => {
            if ('modifier_image' in ingredient.meta && ingredient.meta['modifier_image']) {
              return true
            }
          })
        }

        return false
      },
      async handleSelect(ingredient, side, quantity = 1, group) {
        if (side === 'whole' && this.wholeList.options.length === 0) {
          return
        }
        if (side === 'left' && this.leftList.options.length === 0) {
          return
        }
        if (side === 'right' && this.rightList.options.length === 0) {
          return
        }

        // Determine which modifier to select based on the side
        let leftOption = {}
        let wholeOption = {}
        let rightOption = {}

        // Search Left for option
        for (const opt of this.leftList.options) {
          for (const mod of opt.mods) {
            for (const subOpt of mod.options) {
              if (group === 'popular' && subOpt.name == ingredient.name) {
                leftOption = subOpt
                break
              }
              if (group !== 'popular' && subOpt.name == 'Additional Ingredients') {
                for (const subOptMod of subOpt.mods) {
                  for (const subOptModOpt of subOptMod.options) {
                    if (subOptModOpt.name == ingredient.name) {
                      leftOption = subOptModOpt
                      break
                    }
                  }
                }
              }
            }
          }
        }

        // Search Whole for option
        for (const opt of this.wholeList.options) {
          for (const mod of opt.mods) {
            for (const subOpt of mod.options) {
              if (group === 'popular' && subOpt.name == ingredient.name) {
                wholeOption = subOpt
                break
              }
              if (group !== 'popular' && subOpt.name == 'Additional Ingredients') {
                for (const subOptMod of subOpt.mods) {
                  for (const subOptModOpt of subOptMod.options) {
                    if (subOptModOpt.name == ingredient.name) {
                      wholeOption = subOptModOpt
                      break
                    }
                  }
                }
              }
            }
          }
        }

        // Search Right for option
        for (const opt of this.rightList.options) {
          for (const mod of opt.mods) {
            for (const subOpt of mod.options) {
              if (group === 'popular' && subOpt.name == ingredient.name) {
                rightOption = subOpt
                break
              }
              if (group !== 'popular' && subOpt.name == 'Additional Ingredients') {
                for (const subOptMod of subOpt.mods) {
                  for (const subOptModOpt of subOptMod.options) {
                    if (subOptModOpt.name == ingredient.name) {
                      rightOption = subOptModOpt
                      break
                    }
                  }
                }
              }
            }
          }
        }

        let currentQuantity = 1
        const current = this.getSelected(ingredient, group, false)
        if (current) {
          currentQuantity = current.quantity
        }
         
        // Check and remove the three options and select based on the side
        if (side === 'left') {
          this.removeMod({ ...wholeOption, id: wholeOption.choice_id })
          this.removeMod({ ...rightOption, id: rightOption.choice_id })
          if (this.isSelected(leftOption.choice_id)) {
            this.removeMod({ ...leftOption, id: leftOption.choice_id })
          } else {
            this.selectMod({ ...leftOption, id: leftOption.choice_id, quantity: currentQuantity })
          }
        }
        if (side === 'whole') {
          this.removeMod({ ...leftOption, id: leftOption.choice_id })
          this.removeMod({ ...rightOption, id: rightOption.choice_id })
          if (this.isSelected(wholeOption.choice_id)) {
            this.removeMod({ ...wholeOption, id: wholeOption.choice_id })
          } else {
            this.selectMod({ ...wholeOption, id: wholeOption.choice_id, quantity: currentQuantity })
          }
        }
        if (side === 'right') {
          this.removeMod({ ...leftOption, id: leftOption.choice_id })
          this.removeMod({ ...wholeOption, id: wholeOption.choice_id })
          if (this.isSelected(rightOption.choice_id)) {
            this.removeMod({ ...rightOption, id: rightOption.choice_id })
          } else {
            this.selectMod({ ...rightOption, id: rightOption.choice_id, quantity: currentQuantity })
          }
        }

        // Check the multiplier
        if (side === 'x') {
          if (this.isSelected(leftOption.choice_id)) {
            this.removeMod({ ...leftOption, id: leftOption.choice_id })
            this.selectMod({ ...leftOption, id: leftOption.choice_id, quantity: currentQuantity === quantity ? 1 : quantity })
          }
          else if (this.isSelected(wholeOption.choice_id)) {
            this.removeMod({ ...wholeOption, id: wholeOption.choice_id })
            this.selectMod({ ...wholeOption, id: wholeOption.choice_id, quantity: currentQuantity === quantity ? 1 : quantity })
          }
          else if (this.isSelected(rightOption.choice_id)) {
            this.removeMod({ ...rightOption, id: rightOption.choice_id })
            this.selectMod({ ...rightOption, id: rightOption.choice_id, quantity: currentQuantity === quantity ? 1 : quantity })
          }
          else {
            this.selectMod({ ...wholeOption, id: wholeOption.choice_id, quantity })
          }
        }
      },
      getSelected(ingredient, group, returnIngredient = true) {
        if (this.btnSelected(ingredient.name, 'left', group)) {
          return this.btnSelected(ingredient.name, 'left', group)
        }
        if (this.btnSelected(ingredient.name, 'whole', group)) {
          return this.btnSelected(ingredient.name, 'whole', group)
        }
        if (this.btnSelected(ingredient.name, 'right', group)) {
          return this.btnSelected(ingredient.name, 'right', group)
        }
        if (returnIngredient) {
          return ingredient
        }
        return undefined
      },
      btnSelected(name, side, group) {
        // Find the modifier and check if its selected
        let option = {}
        if (side == 'left') {
          // Search Left for option
          for (const opt of this.leftList.options) {
            for (const mod of opt.mods) {
              for (const subOpt of mod.options) {
                if (group === 'popular' && subOpt.name == name) {
                  option = subOpt
                  break
                }
                if (group !== 'popular' && subOpt.name == 'Additional Ingredients') {
                  for (const subOptMod of subOpt.mods) {
                    for (const subOptModOpt of subOptMod.options) {
                      if (subOptModOpt.name == name) {
                        option = subOptModOpt
                        break
                      }
                    }
                  }
                }
              }
            }
          }
        }

        if (side == 'whole') {
          // Search Whole for option
          for (const opt of this.wholeList.options) {
            for (const mod of opt.mods) {
              for (const subOpt of mod.options) {
                if (group === 'popular' && subOpt.name == name) {
                  option = subOpt
                  break
                }
                if (group !== 'popular' && subOpt.name == 'Additional Ingredients') {
                  for (const subOptMod of subOpt.mods) {
                    for (const subOptModOpt of subOptMod.options) {
                      if (subOptModOpt.name == name) {
                        option = subOptModOpt
                        break
                      }
                    }
                  }
                }
              }
            }
          }
        }

        if (side == 'right') {
          // Search Right for option
          for (const opt of this.rightList.options) {
            for (const mod of opt.mods) {
              for (const subOpt of mod.options) {
                if (group === 'popular' && subOpt.name == name) {
                  option = subOpt
                  break
                }
                if (group !== 'popular' && subOpt.name == 'Additional Ingredients') {
                  for (const subOptMod of subOpt.mods) {
                    for (const subOptModOpt of subOptMod.options) {
                      if (subOptModOpt.name == name) {
                        option = subOptModOpt
                        break
                      }
                    }
                  }
                }
              }
            }
          }
        }

        return this.isSelected(option.choice_id)
      },
      isSelected(id) {
        return this.choices.find(choice => choice.id === id)
      },
      show2X(ingredient) {
        const startsWith = ingredient.name.split(' ')[0]
        if (startsWith && startsWith.toLowerCase() === 'no') {
          return false
        }
        return this.supportsAlternateQuantity && this.alternateQuantityMultiplier > 1
      }
    }
  }
